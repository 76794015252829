@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins";
}

.home-section {
  background-color: #000;
  background-image: url("./../public/assets/img/barbearia-4-dark.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* min-height: 1000px; */
}

/* @media screen and (max-width: 768px) {
  .home-section {
    background-image: url("./../public/assets/img/barbearia-4-dark.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 700px;
  }
} */

.bg-galery {
  background-image: url("./../public/assets/img/barbershop-bg-dark.jpg");
  background-repeat: repeat;
  background-size: auto;
  background-position: center;
  background-attachment: fixed;
}
